export default [
  // *===============================================---*
  // *--------- TICKET   -------------------------------*
  // *===============================================---*
  {
    path: '/query/ticket',
    name: 'lookup-ticket',
    component: () => import('@/views/lookups/ticket/TicketFinder.vue'),
  },
  {
    path: '/query/ticket/:ticketNo',
    name: 'lookup-ticket-no',
    component: () => import('@/views/lookups/ticket/TicketFinder.vue'),
  },

  // *===============================================---*
  // *--------- ORDERS   -------------------------------*
  // *===============================================---*
  {
    path: '/query/order',
    name: 'lookup-order',
    component: () => import('@/views/lookups/order/OrderFinder.vue'),
  },
  // *===============================================---*
  // *--------- QUEUE         --------------------------*
  // *===============================================---*
  {
    path: '/query/queue',
    name: 'lookup-queue',
    component: () => import('@/views/lookups/queue/QueueDetails.vue'),
  },

  // *===============================================---*
  // *--------- CORTAVO  -------------------------------*
  // *===============================================---*
  {
    path: '/cortavo/summary',
    name: 'cortavo-summary',
    component: () => import('@/views/cortavo/CortavoSummary.vue'),
  },
  {
    path: '/cortavo/client/:id',
    name: 'cortavo-view',
    component: () => import('@/views/cortavo/CortavoClientView.vue'),
  },
]
