export default [
  // *===============================================---*
  // *--------- TECH OPERATIONS ROUTES -------------------------------*
  // *===============================================---*
  {
    path: '/tech-ops/summary',
    name: 'techops-summary',
    component: () => import('@/views/techops/TechOpsSummary.vue'),
  },
  {
    path: '/tech-ops/edit/:id',
    name: 'techops-edit',
    component: () => import('@/views/techops/TechOpsEdit.vue'),
  },
]
