import Vue from 'vue'

// Tom's filters
Vue.filter('toCurrency', value => {
  if (Number.isNaN(parseFloat(value))) {
    return value
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })
  return formatter.format(value).replace('.00', '')
})

Vue.filter('toCurrencyK', value => {
  if (Number.isNaN(parseFloat(value))) {
    return value
  }
  const thousands = value / 1000
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
  return `${formatter.format(thousands)}k`
})

Vue.filter('csv', value => value.join(', '))

Vue.filter('filter_tags', value => value.replace(/<\/?[^>]+(>|$)/g, ''))

Vue.filter('k_formatter', num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num))
